<template>
  <div class="reports-page-datatable">
    <el-row :gutter="10">
      <el-col :span="3">
        <p style="font-size: 10.5px; font-style: italic;">Exibindo {{ dataFiltered.length }} registros</p>
      </el-col>
      <el-col :span="14">
        <el-input
          v-model="searchBox"
          size="mini"
          placeholder="Filtrar pelo número da carga, nome do motorista ou veículo"
        />
      </el-col>
      <el-col :span="4">
        <el-tooltip
          class="box-item"
          effect="dark"
          content="Ao exportar todos os campos são sempre enviados"
          placement="top-start"
        >
        <el-switch
          v-model="showAllColumns"
          active-text="Exibir todas as colunas"
          size="small"
        >
        </el-switch>
      </el-tooltip>
      </el-col>
    </el-row>

    <el-table :data="dataFiltered" style="width: 100%; font-size: 11px; padding: 0;" :max-height="screenHeight" class="el-table--mini">
      <el-table-column sortable prop="cargo_id" label="Carga" width="100" fixed>
        <template #default="scope">
          <el-link :href="`${baseurl}/cargos/${scope.row.branch_id}/${scope.row.cargo_id}`" target="_blank" type="primary">{{ scope.row.cargo_id }}</el-link>
        </template>
      </el-table-column>
      <el-table-column sortable prop="branch_name" label="Filial" width="140" />
      <el-table-column sortable prop="cargo_status" label="Status" width="100" />
      <el-table-column v-if="showAllColumns" sortable prop="cargo_status_id" label="Status id" width="50" />
      <el-table-column sortable prop="driver_name" label="Motorista" width="200" />
      <el-table-column sortable prop="checker_name" label="Conferente" width="200" />
      <el-table-column sortable prop="truck_id" label="Veiculo" width="90" />
      <el-table-column sortable resizable prop="truck_model" label="Modelo" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_cap_vol" label="Capacidade Volumes" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_cap_weight" label="Capacidade Peso" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_cost_security" label="Custo Seguro" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_cost_ipva" label="Custo IPVA" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_cost_tracker" label="Custo Rastreador" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_cost_depreciation" label="Custo Depreciação" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_cost_others" label="Outros custos" />
      <el-table-column v-if="showAllColumns" sortable resizable prop="truck_cost_othersdescriptions" label="Otros custos desc" />
      <el-table-column sortable resizable prop="truck_cost_total" label="Custo total" />
      <el-table-column v-if="showAllColumns" sortable prop="truck_started_km" label="Km inicial" width="90" />
      <el-table-column v-if="showAllColumns" sortable prop="truck_finished_km" label="Km final" width="90" />
      <el-table-column sortable prop="truck_traveled_km" label="Km percorrido" width="90" />
      <el-table-column v-if="showAllColumns" sortable prop="truck_traveled_avg" label="Km médio" width="90" />
      <el-table-column sortable prop="cargo_created_at" label="Criada em" width="110" />
      <el-table-column sortable prop="cargo_started_at" label="Iniciada em" width="110" />
      <el-table-column sortable prop="cargo_finished_at" label="Finalizada em" width="110" />
      <el-table-column sortable prop="cargo_traveled_time_s" label="Duração(s)" width="80" />
      <el-table-column v-if="showAllColumns" sortable prop="cargo_traveled_time_avg_s" label="Duração médio" width="80" />
      <el-table-column sortable prop="cargo_duration" label="Duração" width="80" />
      <el-table-column v-if="showAllColumns" sortable prop="cargo_created_by" label="Criada por" width="150" />
      <el-table-column v-if="showAllColumns" sortable prop="cargo_started_by" label="Iniciada por" width="150" />
      <el-table-column v-if="showAllColumns" sortable prop="cargo_finished_by" label="Finalizada por" width="150" />
      <el-table-column sortable prop="cargo_percent_finished" label="% concluída" width="80" />
      <el-table-column sortable prop="total_customers" label="Clientes" width="60" />

      <el-table-column sortable prop="cargo_events" label="Eventos" width="80" />
      <el-table-column sortable prop="cargo_events_ammount" label="Eventos Valor" width="100" />

      <el-table-column sortable prop="total_delivered_customers" label="Entregues" width="60" />
      <el-table-column sortable prop="total_canceled_customers" label="Cancelados" width="60" />
      <el-table-column sortable prop="total_reavailable_customers" label="Reiniciados" width="60" />
      <el-table-column sortable prop="total_invoices" label="Notas" width="60" />
      <el-table-column sortable prop="total_invoices_partial" label="Parciais" width="60" />
      <el-table-column sortable prop="total_invoices_canceled" label="Canceladas" width="60" />
      <el-table-column sortable prop="total_volumes" label="Volumes" width="60" />
      <el-table-column sortable prop="total_amount" label="Valor" width="100" />
      <el-table-column sortable prop="average_ticket" label="Ticket médio" width="100" />
      <el-table-column sortable prop="total_weight" label="Peso" width="60" />
      <el-table-column sortable prop="total_checked_by_fin" label="Conf fin" width="60" />
      <el-table-column sortable prop="total_checked_by_log" label="Conf log" width="60" />
      <el-table-column sortable prop="total_payment_edited" label="Pag editados" width="60" />
    </el-table>
  </div>
</template>

<script>
import exportJson from '@/helpers/exportXLSX'

export default {
  name: 'report-table-all-cargos',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {
        return {
          filial: 'branch_name',
          carga: 'cargo_id',
          carga_status: 'cargo_status',
          carga_status_id: 'cargo_status_id',
          motorista: 'driver_name',
          conferente: 'checker_name',
          veiculo: 'truck_id',
          veiculo_modelo: 'truck_model',
          veiculo_cap_volumes: 'truck_cap_vol',
          veiculo_cap_peso: 'truck_cap_weight',
          veiculo_custo_seguro: 'truck_cost_security',
          veiculo_custo_ipva: 'truck_cost_ipva',
          veiculo_custo_rastreador: 'truck_cost_tracker',
          veiculo_custo_depreciacao: 'truck_cost_depreciation',
          veiculo_custo_outros: 'truck_cost_others',
          veiculo_custo_outros_desc: 'truck_cost_othersdescriptions',
          veiculo_custo_total: 'truck_cost_total',
          veiculo_km_iniciado: 'truck_started_km',
          veiculo_km_finalizado: 'truck_finished_km',
          veiculo_km_percorrido: 'truck_traveled_km',
          veiculo_km_medio: 'truck_traveled_avg',
          criado_em: 'cargo_created_at',
          inicio: 'cargo_started_at',
          fim: 'cargo_finished_at',
          duracao: 'cargo_traveled_time_s',
          duracao_media: 'cargo_traveled_time_avg_s',
          duracao_hh: 'cargo_duration',
          criado_por: 'cargo_created_by',
          iniciado_por: 'cargo_started_by',
          finalizado_por: 'cargo_finished_by',
          porcet_concluido: 'cargo_percent_finished',
          total_clientes: 'total_customers',
          total_entregues: 'total_delivered_customers',
          total_cancelados: 'total_canceled_customers',
          total_reiniciados: 'total_reavailable_customers',
          total_notas: 'total_invoices',
          total_parciais: 'total_invoices_partial',
          total_canceladas: 'total_invoices_canceled',
          total_volumes: 'total_volumes',
          valor_total: 'total_amount',
          ticket_medio: 'average_ticket',
          peso_total: 'total_weight',
          conferido_financeiro: 'total_checked_by_fin',
          conferido_logistica: 'total_checked_by_log',
          total_pagamentos_editados: 'total_payment_edited'
        }
      },
    },
  },
  data() {
    return {
      showAllColumns: false,
      searchBox: '',
      baseurl: window.location.origin
    }
  },
  computed: {
    dataFiltered() {
      return this.data.filter((d) => {
        return (
          !this.searchBox || this.searchBox
            .toLowerCase()
            .split(' ')
            .every((v) =>
              `${d.cargo_id}${d.driver_name}${d.truck_id}`
                .toLowerCase()
                .includes(v)
            )
        )
      })
    },
    screenHeight() {
      return (window.innerHeight -260)
    }
  },
  watch: {},
  mounted() {
    this.$emit('loaded')
  },
  methods: {
    DownloadXLS() {
      exportJson.DownloadXLS(this.dataFiltered, this.fields)
    },
    DownloadCSV() {
      exportJson.DownloadCSV(this.dataFiltered, this.fields)
    },
  },
}
</script>
