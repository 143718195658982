<template>
  <div class="reports-page">
    <!-- Barra de filtros e seleção de data -->
    <global-title-with-filter-bar
      :title="$route.meta.title"
      filtersCargos
      @gFilterSearchClick="filterClick"
      @cFilterOnUpdate="onFilterUpdate"
    />

    <!-- Select e botões de download -->
    <el-row justify="center" type="flex">
      <el-col :span="20" :offset="2">
        <el-form
          ref="reportSelectForm"
          :inline="true"
          class="el-form-fullwidth"
          size="mini"
          label-position="right"
        >
          <el-form-item label="Relatórios" class="el-form-item-no-botton-margin">
            <el-select
              v-model="selectedReport"
              placeholder="Selecione o relatório desejado"
            >
              <el-option
                v-for="(report, index) in reports"
                :key="index"
                :label="report.name"
                :value="report.getter"
              />
            </el-select>
          </el-form-item>

          <el-form-item class="el-form-item-no-botton-margin">
            <el-button
              @click="DownloadXLS"
              icon="el-icon-download"
              v-if="showDownloadButtons"
              >XLS</el-button
            >
            <el-button
              @click="DownloadCSV"
              icon="el-icon-download"
              v-if="showDownloadButtons"
              >CSV</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <!-- RElatórios conforme seleção realizada -->
    <report-table-stats-by-city
      ref="tbComp"
      :data="reportData"
      v-if="selectedReport === 'Cargos/getByPlacesGeneralStatisticsClean(city)'"
      @loaded="showDownloadButtons = true"
    />
    <report-table-stats-by-state
      ref="tbComp"
      :data="reportData"
      v-if="
        selectedReport === 'Cargos/getByPlacesGeneralStatisticsClean(state)'
      "
      @loaded="showDownloadButtons = true"
    />
    <report-table-stats-by-driver
      ref="tbComp"
      :data="reportData"
      v-if="
        selectedReport ===
        'Cargos/getByDriverWorkedHoursAndDeliveryCount(cleanData)'
      "
      @loaded="showDownloadButtons = true"
    />
    <report-table-stats-by-truck
      ref="tbComp"
      :data="reportData"
      v-if="selectedReport === 'Cargos/getWorkedHoursAndKMByTruck(cleanData)'"
      @loaded="showDownloadButtons = true"
    />
    <report-table-list-of-error
      ref="tbComp"
      :data="reportData"
      v-if="selectedReport === 'Cargos/getListErrors(cleanData)'"
      @loaded="showDownloadButtons = true"
    />
    <report-table-all-cargos
      ref="tbComp"
      :data="reportData"
      v-if="selectedReport === 'Cargos/getAllCargosForReport(null)'"
      @loaded="showDownloadButtons = true"
    />
    <report-table-all-cargos-simple
      ref="tbComp"
      :data="reportData"
      v-if="selectedReport === 'Cargos/getAllCargosForReportSimple(null)'"
      @loaded="showDownloadButtons = true"
    />
    <report-table-all-cargos-extended
      ref="tbComp"
      :data="reportData"
      v-if="selectedReport === 'Cargos/getAllCargosForReportExtended(null)'"
      @loaded="showDownloadButtons = true"
    />
    <report-table-cargos-fin
      ref="tbComp"
      :data="reportData"
      v-if="selectedReport === 'Cargos/getReportsCargosFin(null)'"
      @loaded="showDownloadButtons = true"
    />
  </div>
</template>

<script>
import globalTitleWithFilterBar from '@/components/Menu/globalTitleWithFilterBar'
import reportTableStatsByCity from './datatables/statsByCity'
import reportTableStatsByState from './datatables/statsByState'
import reportTableStatsByDriver from './datatables/statsByDriver'
import reportTableStatsByTruck from './datatables/statsByTruck'
import reportTableListOfError from './datatables/listOfError'
import reportTableAllCargos from './datatables/tableAllCargos'
import reportTableAllCargosSimple from './datatables/tableAllCargosSimple'
import reportTableAllCargosExtended from './datatables/tableAllCargosExtended'
import reportTableCargosFin from './datatables/tableAllCargosFin'
import { mapActions } from 'vuex'

export default {
  name: 'reports-index',
  components: {
    globalTitleWithFilterBar,
    reportTableStatsByCity,
    reportTableStatsByState,
    reportTableStatsByDriver,
    reportTableStatsByTruck,
    reportTableListOfError,
    reportTableAllCargos,
    reportTableAllCargosSimple,
    reportTableAllCargosExtended,
    reportTableCargosFin
  },
  data() {
    return {
      reports: [
        {
          getter: 'Cargos/getWorkedHoursAndKMByTruck(cleanData)',
          name: 'Estatistico por veículo',
        },
        {
          getter: 'Cargos/getByDriverWorkedHoursAndDeliveryCount(cleanData)',
          name: 'Estatistico por motorista',
        },
        {
          getter: 'Cargos/getByPlacesGeneralStatisticsClean(city)',
          name: 'Estatistico por cidade',
        },
        {
          getter: 'Cargos/getByPlacesGeneralStatisticsClean(state)',
          name: 'Estatistico por Estado',
        },
        {
          getter: 'Cargos/getListErrors(cleanData)',
          name: 'Relação de erros nas cargas',
        },
        {
          getter: 'Cargos/getAllCargosForReport(null)',
          name: 'Relação de cargas agrupado',
        },
        {
          getter: 'Cargos/getAllCargosForReportSimple(null)',
          name: 'Relação de cargas simplificado',
        },
        {
          getter: 'Cargos/getAllCargosForReportExtended(null)',
          name: 'Relação de cargas detalhado',
        },
        {
          getter: 'Cargos/getReportsCargosFin(null)',
          name: 'Financeiro por carga',
        },
      ],
      selectedReport: '',
      getterResult: {},
      showDownloadButtons: false,
    }
  },
  computed: {
    reportData() {
      // console.log('computed',Object.values(this.getterResult));
      return Object.values(this.getterResult)
    },
  },
  watch: {
    selectedReport(value) {
      this.onReportSelected(value, false)
    },
  },
  mounted() {
    this.getAndMonitorCargos({ showMsg: false, force: true })
  },
  methods: {
    ...mapActions('Cargos', ['getAndMonitorCargos']),
    filterClick() {
      this.onReportSelected(this.selectedReport, true)
    },
    onFilterUpdate() {
      this.onReportSelected(this.selectedReport, true)
    },
    async onReportSelected(value, executeFilterCarga) {
      if (executeFilterCarga)
        await this.getAndMonitorCargos({ showMsg: true, force: true })

      this.showDownloadButtons = false
      // Aplico o getter associado ao report selecionado
      if (value && typeof value === 'string' && value !== '') {
        // Valido a sintaxe do getter e se o mesmo é uma função com parametros ou um getter tradicional
        let testRegex = /(\w+\/\w+)\((\w+)(, )?(\w+)?\)/g.exec(value)
        if (testRegex !== null && Array.isArray(testRegex)) {
          if (typeof this.$store.getters[testRegex[1]] === 'function') {
            if (testRegex.length === 3)
              this.getterResult = this.$store.getters[testRegex[1]](
                testRegex[2]
              )
            else if (testRegex.length === 5)
              this.getterResult = this.$store.getters[testRegex[1]](
                testRegex[2],
                testRegex[4]
              )
          } else {
            const tipo = typeof this.$store.getters[testRegex[1]]
            console.warn(`Invalid function getter (${tipo}) ${value}`)
          }

          // Se for um getter simples, retorna seu valor direto
        } else {
          if (
            ['string', 'number', 'object', 'boolean'].includes(
              typeof this.$store.getters[value]
            )
          ) {
            this.getterResult = this.$store.getters[value]
          } else {
            const tipo = typeof this.$store.getters[value]
            console.warn(`Invalid getter (${tipo}) ${value}`)
          }
        }
      }

      // console.log('getterResult: ', this.getterResult)
    },
    DownloadXLS() {
      this.$refs.tbComp.DownloadXLS()
    },
    DownloadCSV() {
      this.$refs.tbComp.DownloadCSV()
    },
  },
}
</script>
